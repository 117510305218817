export const projects = [
  {
    id: 1,
    title: "Online Library Platform",
    description: "Multifunctional e-commerce application that allows users to browse and purchase books online.",
    techs: ["React", "JavaScript", "CSS"],
    github: "https://github.com/JackJUson/e-library",
    live: "https://e-library-sooty.vercel.app/",
  },
  {
    id: 2,
    title: "Movie Plus",
    description: "A simple web application that provides details about your favourite movies and tv-shows!",
    techs: ["JavaScript", "HTML", "CSS"],
    github: "https://github.com/JackJUson/movie-plus",
    live: "https://jackjuson.github.io/movie-plus/",
  },
  {
    id: 3,
    title: "Make an event on Google Calender",
    description: "Post an event and see it on your schedules on Google Calender.",
    techs: ["JavaScript", "HTML", "CSS"],
    github: "https://github.com/JackJUson/google-calender",
    live: "https://google-calender-three.vercel.app/",
  },
  {
    id: 4,
    title: "Three dimensional BMW showcase",
    description: "Interact with a 3D modal of BMW SRT performance. Try rotating and zooming with a simple drag!",
    techs: ["React", "Three.js", "JavaScript"],
    github: "https://github.com/JackJUson/3d-client",
    live: "https://3d-client.vercel.app/",
  },
  {
    id: 5,
    title: "ToDo List",
    description: "A minimalistic todo list web application made with SvelteKit. Comes with all the essential functionality like add, edit and delete.",
    techs: ["Svelte", "React", "JavaScript"],
    github: "https://github.com/JackJUson/todo-list",
    live: "https://todo-list-drab-nine.vercel.app/",
  },
  {
    id: 6,
    title: "Personal Website V3",
    description: "Third iteration of my personal website built with React, Tailwind and with a custom domain!",
    techs: ["React", "Tailwind", "JavaScript"],
    github: "https://github.com/JackJUson/v3",
    live: "www.jacksonwjung.com/",
  },
  {
    id: 7,
    title: "Tell me the weather..",
    description: "A simple react application showing you the weather of your searched city, hosted by Vercel Production",
    techs: ["TypeScript", "React", "CSS"],
    github: "https://github.com/JackJUson/weather-app",
    live: "https://weather-dmt8wlvx4-jackjuson.vercel.app/",
  },
  {
    id: 8,
    title: "Travel Adviser",
    description: "Mock version of a travel booking website with video background and beautiful transition",
    techs: ["React", "SCSS", "JavaScript"],
    github: "https://github.com/JackJUson/travel-adviser",
    live: "https://travel-adviser-xi.vercel.app/",
  },
  {
    id: 9,
    title: "Personal Website V2",
    description: "Second iteration of my online portfolio, dark mode, email contact and pop up modal and more functionality!",
    techs: ["JavaScript", "HTML", "CSS"],
    github: "https://github.com/JackJUson/v2",
    live: "",
  },
  {
    id: 10,
    title: "Personal Website V1",
    description: "First iteration of my online portfolio, made with simple HTML, CSS and JavaScript. Hosted by Github Pages",
    techs: ["JavaScript", "HTML", "CSS"],
    github: "https://github.com/JackJUson/v1",
    live: "https://jackjuson.github.io/v1/",
  },
  {
    id: 11,
    title: "Your artificial intelligent chat buddy",
    description: "Simple mock version of the famous chatgpt built using the free OpenAi api, complete with conversational AI response",
    techs: ["JavaScript", "HTML", "CSS"],
    github: "https://github.com/JackJUson/ai-chat",
    live: "https://ai-chat-opal.vercel.app/",
  },
  {
    id: 12,
    title: "Search Engine",
    description: "A program that searches for and identifies information in a database that correspond to keywords or characters specified by the user.",
    techs: ["JavaScript", "HTML", "CSS"],
    github: "https://github.com/JackJUson/search-engine",
    live: "https://search-engine-six-rust.vercel.app/",
  },
];
